import logo from "./logo.svg";
import "./App.css";
import React from "react";
import RoomStats from "./components/RoomStats";

function App() {
  return (
    <div className="App">
      <h1>Meta-MJ Room Status</h1>
      <RoomStats />
    </div>
  );
}

export default App;
