export const priceList = [
  {
    priceModeId: "0_5-1",
    basePrice: 0.5,
    specialPrice: 1.0,
  },
  {
    priceModeId: "1_5-3",
    basePrice: 1.5,
    specialPrice: 3.0,
  },
  {
    priceModeId: "4-8",
    basePrice: 4.0,
    specialPrice: 8.0,
  },
  {
    priceModeId: "7-14",
    basePrice: 7.0,
    specialPrice: 14.0,
  },
  {
    priceModeId: "15-30",
    basePrice: 15.0,
    specialPrice: 30.0,
  },
  // {
  //   priceModeId: "70-140",
  //   basePrice: 70.0,
  //   specialPrice: 140.0,
  // },
  {
    priceModeId: "140-280",
    basePrice: 140.0,
    specialPrice: 280.0,
  },
];
