import React, { useState, useEffect } from "react";
import firestore from "../firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";
import { priceList } from "../priceList";
import { Container, Row, Col, Table, Button } from "reactstrap";
import { Howl } from "howler";
import "./RoomStats.css";

const AUTO_REFRESH_ENABLE = process.env.REACT_APP_AUTO_REFRESH_ENABLE === "true" || false;

const RoomStats = () => {
  const [startMonitor, setStartMonitor] = useState(false);

  const [roomData, setRoomData] = useState({
    basicRooms: [],
    selfDrawnRooms: [],
  });

  const [hasPlayers, setHasPlayers] = useState(false);

  useEffect(() => {
    const fetchRooms = async () => {
      const basicCollections = priceList.map((priceItem) => `BASIC-${priceItem.priceModeId}`);
      const selfDrawnCollections = priceList.map((priceItem) => `SELF_DRAWN-${priceItem.priceModeId}`);

      let basicRoomsData = [];
      let selfDrawnRoomsData = [];

      for (let coll of basicCollections) {
        const data = await getCollectionData(coll);
        basicRoomsData.push({ collection: coll, data });
      }
      for (let coll of selfDrawnCollections) {
        const data = await getCollectionData(coll);
        selfDrawnRoomsData.push({ collection: coll, data });
      }

      console.log("refreshing...");

      setRoomData({ basicRooms: basicRoomsData, selfDrawnRooms: selfDrawnRoomsData });

      // Check if any room has players
      const anyRoomHasPlayers =
        basicRoomsData.some((room) => room.data.some((roomData) => roomData.playerCount > 0)) ||
        selfDrawnRoomsData.some((room) => room.data.some((roomData) => roomData.playerCount > 0));
      setHasPlayers(anyRoomHasPlayers);

      // console.log("basicRoomsData", basicRoomsData);
      console.log("anyRoomHasPlayers", anyRoomHasPlayers);

      // Play the sound if there are players in any room
      if (anyRoomHasPlayers) {
        console.log("playing alert sound");
        const sound = new Howl({ src: ["/sounds/alert.mp3"] });
        sound.play();
      }
    };

    if (startMonitor) {
      console.log("start monitoring...");
      fetchRooms();

      if (AUTO_REFRESH_ENABLE) {
        const interval = setInterval(fetchRooms, parseInt(process.env.REACT_APP_AUTO_REFRESH_MS));
        return () => clearInterval(interval); // Cleanup interval on component unmount
      }
    }
  }, [startMonitor]);

  const getCollectionData = async (coll) => {
    const gamePriceModeRef = collection(firestore, coll);
    const q = query(gamePriceModeRef, where("gameStarted", "==", false));
    const querySnapshot = await getDocs(q);

    // console.log("Coll", coll, querySnapshot.docs);

    let roomData = querySnapshot.docs.map((doc) => ({
      roomId: doc.id,
      playerCount: Object.keys(doc.data().playerParams).length,
    }));

    // console.log("roomData", roomData);

    return roomData;
  };

  const renderTable = (roomsData) => (
    <table>
      <thead>
        <tr>
          <th>Collection Name</th>
          <th>Available Rooms</th>
          <th>Player Count</th>
        </tr>
      </thead>

      <tbody>
        {roomsData.map((roomInfo, index) => (
          <tr key={index}>
            <td>{roomInfo.collection}</td>
            <td>{roomInfo.data.map((room) => room.roomId).join(", ")}</td>
            <td className={roomInfo.data.some((room) => room.playerCount !== 0) ? "flash" : ""}>
              {roomInfo.data.map((room) => room.playerCount).join(", ")}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  return (
    <Container>
      <Button color="primary" onClick={() => setStartMonitor(!startMonitor)}>
        {startMonitor ? "Stop" : "Start"} Monitoring
      </Button>
      <Row>
        <Col md="6">
          <h2>BASIC</h2>
          {renderTable(roomData.basicRooms)}
        </Col>
        <Col md="6">
          <h2>SELF-DRAWN</h2>
          {renderTable(roomData.selfDrawnRooms)}
        </Col>
      </Row>
    </Container>
  );
};

export default RoomStats;
